import React from 'react';
import Layout from '../components/Layout';

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/products/banner/3.jpg)`,
                        }}
                    >
                        <h1>Prelavado de botellas （Unidad)</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Zion Tech suministra dos tipos de máquinas de prelavado de botellas de PET, tipo continuo
                                y tipo por lotes. La característica más importante del prelavador de botellas continuo
                                es que posee un sistema de circulación de agua automatizado y alimentación de entrada
                                continua, luego utiliza agua química a alta temperatura para Lavado de botellas. Este
                                tipo de lavado elimina etiquetas, suciedad, arenas, pegamentos, gomas, metales y otras
                                impurezas en la superficie de las botellas. La tasa de separación de impurezas es
                                superior al 90%. El sistema de reciclaje de agua química suplementaria logra la
                                separación completa entre el agua química y botellas, y prolonga la vida útil del agua
                                química y ahorra costos de aditivos.
                            </p>
                            <p style={{color: '#000'}}>
                                El lavado por lotes asegura que las botellas de PET tengan suficiente tiempo de remojo
                                en agua química, se aplicará para reciclar bien las botellas de aceite.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Caracteristicas</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                El prelavado de botellas tiene como objetivo eliminar las impurezas externas antes del
                                triturado de la botella de PET tanto como sea posible. Es un proceso importante en la
                                producción de reciclaje de botellas de PET. Y a través de su lavado a alta temperatura,
                                asegura la calidad de las escamas de rPET del producto final y reduce El costo de
                                producción Especialmente para la unidad de trituración, puede evitar la abrasión
                                innecesaria de las cuchillas por excesivas impurezas sólidas duras.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Capacidad de entrada</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                1500-6000 kg/h
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
